import { ChainId, JSBI, Percent, Token } from '@pancakeswap/sdk'
import { parseUnits } from '@ethersproject/units'
import { FacebookIcon, TelegramIcon, TwitterIcon, YoutubeIcon } from '@pancakeswap/uikit'
import { BigNumber } from '@ethersproject/bignumber'
import { MAIN_TOKEN2, BUSD, MAIN_TOKEN3, MAIN_TOKEN, MAIN_TOKEN4, USDT, WBNB } from './tokens'
import { ChainTokenList } from './types'


export const ROUTER_ADDRESS = {
  [ChainId.BSC]: '0x2864534e3664f31bedb0a7c71d1a4a04733c5a01',
  [ChainId.BSC_TESTNET]: '0x0C4AE0A3890350eA4139B30eD3403e7849C3B118',
}

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  [ChainId.BSC]: [MAIN_TOKEN[ChainId.BSC], USDT[ChainId.BSC], BUSD[ChainId.BSC], WBNB[ChainId.BSC], MAIN_TOKEN2[ChainId.BSC], MAIN_TOKEN3[ChainId.BSC], MAIN_TOKEN4[ChainId.BSC]],
  [ChainId.BSC_TESTNET]: [MAIN_TOKEN[ChainId.BSC_TESTNET], USDT[ChainId.BSC_TESTNET], BUSD[ChainId.BSC_TESTNET], WBNB[ChainId.BSC_TESTNET], MAIN_TOKEN2[ChainId.BSC_TESTNET], MAIN_TOKEN3[ChainId.BSC_TESTNET], MAIN_TOKEN4[ChainId.BSC_TESTNET]],
}

/**
 * Additional bases for specific tokens
 * @example { [WBTC.address]: [renBTC], [renBTC.address]: [WBTC] }
 */
export const ADDITIONAL_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.BSC]: {},
}

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 * @example [AMPL.address]: [DAI, WNATIVE[ChainId.BSC]]
 */
export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.BSC]: {},
}

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  [ChainId.BSC]: [MAIN_TOKEN[ChainId.BSC], USDT[ChainId.BSC], WBNB[ChainId.BSC], MAIN_TOKEN2[ChainId.BSC], MAIN_TOKEN3[ChainId.BSC], MAIN_TOKEN4[ChainId.BSC]],
  [ChainId.BSC_TESTNET]: [MAIN_TOKEN[ChainId.BSC_TESTNET], USDT[ChainId.BSC_TESTNET], WBNB[ChainId.BSC_TESTNET], MAIN_TOKEN2[ChainId.BSC_TESTNET], MAIN_TOKEN3[ChainId.BSC_TESTNET], MAIN_TOKEN4[ChainId.BSC_TESTNET]],
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  [ChainId.BSC]: [MAIN_TOKEN[ChainId.BSC], USDT[ChainId.BSC], WBNB[ChainId.BSC], MAIN_TOKEN2[ChainId.BSC], MAIN_TOKEN3[ChainId.BSC], MAIN_TOKEN4[ChainId.BSC]],
  [ChainId.BSC_TESTNET]: [MAIN_TOKEN[ChainId.BSC_TESTNET], USDT[ChainId.BSC_TESTNET], BUSD[ChainId.BSC_TESTNET], WBNB[ChainId.BSC_TESTNET], MAIN_TOKEN2[ChainId.BSC_TESTNET], MAIN_TOKEN3[ChainId.BSC_TESTNET], MAIN_TOKEN4[ChainId.BSC_TESTNET]],
}

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  [ChainId.BSC]: [
    [MAIN_TOKEN[ChainId.BSC], WBNB[ChainId.BSC]],
    [MAIN_TOKEN[ChainId.BSC], USDT[ChainId.BSC]],
    [MAIN_TOKEN[ChainId.BSC], MAIN_TOKEN2[ChainId.BSC]],
    [MAIN_TOKEN[ChainId.BSC], MAIN_TOKEN3[ChainId.BSC]],
    [MAIN_TOKEN[ChainId.BSC], MAIN_TOKEN4[ChainId.BSC]],
    [MAIN_TOKEN2[ChainId.BSC], WBNB[ChainId.BSC]],
    [MAIN_TOKEN3[ChainId.BSC], WBNB[ChainId.BSC]],
    [MAIN_TOKEN3[ChainId.BSC], MAIN_TOKEN4[ChainId.BSC]],
  ],
}

export const BIG_INT_ZERO = JSBI.BigInt(0)
export const BIG_INT_TEN = JSBI.BigInt(0)

// one basis point
export const BIPS_BASE = JSBI.BigInt(10000)
export const ONE_BIPS = new Percent(JSBI.BigInt(1), BIPS_BASE)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much ETH so they end up with <.01
export const MIN_BNB: JSBI = JSBI.exponentiate(BIG_INT_TEN, JSBI.BigInt(16)) // .01 ETH
export const BETTER_TRADE_LESS_HOPS_THRESHOLD = new Percent(JSBI.BigInt(50), BIPS_BASE)

export const ZERO_PERCENT = new Percent('0')
export const ONE_HUNDRED_PERCENT = new Percent('1')

export const BASE_FEE = new Percent(JSBI.BigInt(25), BIPS_BASE)
export const INPUT_FRACTION_AFTER_FEE = ONE_HUNDRED_PERCENT.subtract(BASE_FEE)

// BNB
export const DEFAULT_INPUT_CURRENCY = {
  [ChainId.BSC]:'ETH',
  [ChainId.BSC_TESTNET]: 'ETH'
}
// CAKE
export const DEFAULT_OUTPUT_CURRENCY = {
  [ChainId.BSC]: MAIN_TOKEN[ChainId.BSC].address,
  [ChainId.BSC_TESTNET]: MAIN_TOKEN[ChainId.BSC_TESTNET].address,
}

// Handler string is passed to Gelato to use PCS router
export const GELATO_HANDLER = 'pancakeswap'
export const GENERIC_GAS_LIMIT_ORDER_EXECUTION = BigNumber.from(500000)

export const LIMIT_ORDERS_DOCS_URL = 'https://docs.punkswap.exchange/products/pancakeswap-exchange/limit-orders'
export const SUPPORT_CHAINS = [56, 97]
export const DEFAULT_CHAIN = 56;

export const bsc = {
  id: 56,
  name: 'Binance Mainnet',
  network: 'bsc',
  rpcUrls: {
    default: 'https://bsc-dataseed1.bnbchain.org',
    public: 'https://bsc-dataseed1.bnbchain.org',
  },
  blockExplorers: {
    default: { name: 'BaseScan', url: 'https://bscscan.com/' },
  },
  multicall: {
    address: '0xcf591ce5574258ac4550d96c545e4f3fd49a74ec',
    blockCreated: 7162653,
  },
  nativeCurrency: {
    name: 'BNB',
    symbol: 'BNB',
    decimals: 18,
  },
}

export const bscTest = {
  id: 97,
  name: 'Bsc Testnet',
  network: 'bscTesnet',
  nativeCurrency: {
    decimals: 18,
    name: 'BNB',
    symbol: 'BNB',
  },
  rpcUrls: {
    default: 'https://data-seed-prebsc-1-s2.bnbchain.org:8545',
    public: 'https://data-seed-prebsc-1-s2.bnbchain.org:8545',
  },
  blockExplorers: {
    default: { name: 'BaseScan', url: 'https://testnet.bscscan.com/' },
  },
  multicall: {
    address: '0xa54fe4a3dbd1eb21524cd73754666b7e13b4cb18',
    blockCreated: 9495869,
  },
  testnet: true,
}


export const trimAddress = (addr) => {
  return `${addr.substring(0, 6)}...${addr.substring(addr.length - 4)}`;
}

export const getChainId = (chain, isPure = false) => {
  if (!isPure && chain && chain.id && SUPPORT_CHAINS.includes(chain.id)) {
    return chain.id;
  }

  if (isPure && chain && SUPPORT_CHAINS.includes(chain)) {
    return chain;
  }
  return DEFAULT_CHAIN;
};


export const socials = [
  {
    label: "Facebook",
    icon: FacebookIcon,
    href: "https://www.facebook.com/jkpcoin99/",
  },
  {
    label: "Telegram",
    icon: TelegramIcon,
    href: "https://t.me/jkpcoin99"
  },
  {
    label: "Twitter",
    icon: TwitterIcon,
    href: "https://x.com/JKPCOIN",
  }
];


export const GAS_PRICES = {
  [ChainId.BSC]: {
    "standard": parseUnits('2', 'gwei').toString(),
    "Fast": parseUnits('4', 'gwei').toString(),
    "Instant": parseUnits('5', 'gwei').toString()
  },
  [ChainId.BSC_TESTNET]: {
    "standard": parseUnits('5', 'gwei').toString(),
    "Fast": parseUnits('10', 'gwei').toString(),
    "Instant": parseUnits('15', 'gwei').toString()
  }
}


export const USDT_BNB_LP_ADDRESS = {
  [ChainId.BSC]:"0x16b9a82891338f9bA80E2D6970FddA79D1eb0daE",
  [ChainId.BSC_TESTNET] : "0xf76723df860610611b2df4ad7787ad476e12e2c2"
}

export const MAIN_TOKEN_LP_ADDRESS = {
  [ChainId.BSC]:"0x4a53e570baa320e4028d4fda1fe8da953b9b1b27",
  [ChainId.BSC_TESTNET] : "0xf76723df860610611b2df4ad7787ad476e12e2c2"
}

export const TOTAL_SUPPLY = 5500000000

